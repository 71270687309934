import $ from 'jquery'

class Search {

      // 1. describe and create/initiate our object
      constructor() {
        this.addsearchHTML();
        this.openButton = $(".btn-search");
        this.closeButton = $(".button-modal-close");
        this.searchContent = $('.modal-search');
        this.SearchField = $("#search-term");
        this.events();
        this.isOverlayOpen = false;
        this.inSpinnerVisible = false;
        this.previusValueKey;
        this.typingTimer;
        this.resultDiv = $('#search__result');


      }

      // 2. events
      events(){
        this.openButton.on("click", this.openOverlay.bind(this));
        this.closeButton.on("click", this.closeOverlay.bind(this));
        $(document).on('keydown', this.keyPressDispatcher.bind(this));
        this.SearchField.on('keyup', this.TypingLogic.bind(this) );
      }

      //3. methods (function our action)

      TypingLogic(){
        if(this.SearchField.val() != this.previusValueKey){
          clearTimeout(this.typingTimer);
          if(this.SearchField.val()){
            if(this.inSpinnerVisible == false){
              this.resultDiv.html('<div class="spinner-loader"></div>')
              this.inSpinnerVisible = true;

            }

            this.typingTimer = setTimeout(this.getRusult.bind(this) ,700);

          } else{
            this.resultDiv.html('');

            this.inSpinnerVisible = false;

          }

          this.previusValueKey = this.SearchField.val();
        }


      }

      getRusult(){
        $.getJSON(dfOmniaData.root_url + '/wp-json/dfomnia/v1/search?term=' + this.SearchField.val(), (results) => {

            this.resultDiv.html(`
              <div class="col-50 ">
              <h2 class="search-title">Pagine</h2>
              ${results.page.length ? "<ul>" : "<p>Nessuna pagina trovata con la parola " +  this.SearchField.val() + "</p>"}
              ${results.page.map(item => `<li><a href="${item.permalink}">${item.title}</a></li>`).join("")}
              ${results.page.length ? "</ul>" : ""}
              </div>
              <div class="col-50 ">
              <h2 class="search-title">Ingredienti</h2>
              ${results.ingredienti.length ? "<ul>" : "<p>Nessun ingrediente trovato con la parola " +  this.SearchField.val() + "</p>"}
              ${results.ingredienti.map(item => `<li><a href="${item.permalink}">${item.title}</a> <a target="_blank" href="${item.permalink + '?print=pdf'}">Scheda PDF</a></li>`).join("")}
              ${results.ingredienti.length ? "</ul>" : ""}
              </div>
              `)



        });


      }



      keyPressDispatcher(e){
        //open key S
        if(e.keyCode == 83 && !this.isOverlayOpen && !$("input, textarea").is(':focus')){
          this.openOverlay();
        }
        if(e.keyCode == 27 && this.isOverlayOpen){
          this.closeOverlay();
        }
      }

    openOverlay(){
      this.searchContent.addClass("modal-search--open");
      $("body").addClass("body-no-scroll");
      this.SearchField.val('');
      this.resultDiv.html('');
      setTimeout(()=>this.SearchField.focus(), 301)
      this.isOverlayOpen = true;




    }
    closeOverlay(){
      this.searchContent.removeClass("modal-search--open")
      $("body").removeClass("body-no-scroll")
      this.SearchField.val('');
        this.resultDiv.html('');
      this.isOverlayOpen = false;


    }

    addsearchHTML(){
        $('body').append(`
          <div class="modal-search">
          <div class="modal-container">
              <div class="modal-container--input">
                  <input id="search-term" type="text" class="search--item" placeholder="Inserisci il prodotto da ricercare">
                  <button class="icon-button button-modal-close">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path fill="currentColor" d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z" />
                  </svg>
                </button>
              </div>
              <div  id="search__result" class="modal-container--results grid">



              </div>
            <div class="modal-container--footer">
              <button button-modal-close class="button button-modal-close">Chiudi</button>
            </div>
        </div>
      </div>
        `)
      }
}

export default Search;
