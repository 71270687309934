// import Swiper JS
import Swiper, { Autoplay} from 'swiper';

// import Swiper styles
import 'swiper/css';
Swiper.use([Autoplay]);
var swiper = new Swiper(".mySwiper", {
        slidesPerView: 5,
        spaceBetween: 30,
        freeMode: true,
        loop: true,
        centeredSlides: true,
        breakpoints: {
    // when window width is >= 320px
            320: {
              slidesPerView:1,
              spaceBetween: 20
            },
            // when window width is >= 480px
            480: {
              slidesPerView: 1,
              spaceBetween: 30
            },
            // when window width is >= 640px
            640: {
              slidesPerView: 2,
              spaceBetween: 30
            },
            1200: {
              slidesPerView: 6,
              spaceBetween: 30
            }
          },
        autoplay: {
          delay: 2500,
          disableOnInteraction: true
        },
        //loopFillGroupWithBlank: true,

      });
