import "../css/style.scss";
import "./modules/slide.js";
import "./modules/mobileClass.js";
import Search from "./modules/Search";
import $ from 'jquery';

const search = new Search();

$(window).scroll(function() {
    var scroll = $(window).scrollTop();

     //>=, not <=
    if (scroll >= 100) {
        //clearHeader, not clearheader - caps H
        $("body").addClass("scroll-down");
    }
    if (scroll <= 500) {
        //clearHeader, not clearheader - caps H
        $("body").removeClass("scroll-down");
    }

});
jQuery(document).ready(function($) {
    $('.dropdown a').addClass('dropbtn');
   


    $(".dropbtn").click(function(){
        $(this).siblings('.dropdown-menu').toggleClass('display');
      });
 
});

const overlay = document.querySelector(".overlay");
const body = document.querySelector("body");
const menuBtn = document.querySelector(".menu-btn");
const menu = document.querySelector("#menu")



function toggle() {
    // disable overflow body
    body.classList.toggle("overflow");
    // dark background
    overlay.classList.toggle("overlay--active");
    // add open class
    menuBtn.classList.toggle("open");
   //add open class menu
   menu.classList.toggle("open");
}

menuBtn.addEventListener("click", e => {
    e.stopPropagation();
    toggle();
})


window.onkeydown = function( event ) {
    const key = event.key; // const {key} = event; in ES6+
    const active = menuItems.classList.contains('open');
    if (key === "Escape" && active) {
        toggle();
    }
};




// if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/){
//     document.write("mobile ok");
//   }else{
//     document.write("not mobile");
//   }


